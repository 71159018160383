.header {
    display: flex;
 
    
    line-height: 20px;
    top: 64px;
    background-color: #FFFF;
 
    color: #ffd36b;
    
    z-index: 999;
  }
  .sticky {
    position: fixed;
    top: 64px;
    width: 100%;
    padding-bottom: 2px;
  }

  .left{
    display: flex;
    top : 124px;
    z-index: 999;
  }
  .stickyleft {
    position: fixed;
    top : 124px;
    width: 256;
  }